import { BrowserRouter as Router, Routes, Route, /*Link*/ } from 'react-router-dom';  

import './App.scss';

import Home from './pages/home/Home';

function App() {
    return (
        <Router>            
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
